/* header start */
.header{
    background-color: rgba(255, 255, 255, .8);
    position:fixed;
    top:0;
    width:100%;
    height:3rem;
    backdrop-filter: saturate(180%) blur(20px);
    z-index: 1;
}
.header>ul{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding:0.625rem 1.5rem; 
}
.navItems{
    text-decoration: none;
    display: flex;
    gap:2rem;
}
.header>ul>div>li{
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
.header>ul>div>li>a{
    color: var(--color-black);
    text-decoration: none;
}
/* header end */
/*container start*/
/*container end*/
/* hero section start */
.hero-text-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:1rem;
}
.hero-text-container>h1{
    text-align: center;
}
.hero-text-container>p{
    text-align: center;
    margin:0;
    font-size: var(--font-size-body-hero);
    line-height: var(--font--line-hieght-body-hero);
    font-weight: var(--font-weight-body-hero);
    color:var(--color-black);
}
.hero-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:2rem;
    padding-left:4rem;
    padding-right:4rem;
}
.button-group{
    display: flex;
    flex-direction: rows;
    justify-content: center;
    align-items: center;
    gap:1rem;
}

.hero-image-container{
    height:616px;
    width: 100%;  
    display: block;   
}

.hero-image-container>svg{
    width: fit-content;
    transform: translateX(-20%);
    padding-top:1rem;
    width:1720px;
}
@media (max-width: 1740px) {
    .hero-image-container{
       overflow: hidden;
       height: fit-content;
       margin-bottom: 6rem;
       height:600px;
   }
   .hero-image-container>svg{
    transform: none;
    position: absolute;
    left:1rem;
    
}
}

@media (max-width: 1080px) {
     .hero-image-container{
        
        overflow: hidden;
        height: fit-content;
        margin-bottom: 6rem;
    }
   .hero-image-container>svg{
        position: relative;
        transform: none;
        width:100%;
        left:0;
    }
}
@media (max-width: 598px) {
    .hero-section{
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .hero-text-container{
        text-align: center;
        
    }
    .hero-image-container{
        overflow: hidden;
        height: fit-content;
        margin-bottom: 6rem;
    }
   .hero-image-container>svg{
        transform: none;
        width:100%;
    }
}
@media (max-width: 400px) {
    .hero-section{
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .button-group{
        flex-direction: column;
    }
    .hero-image-container{
        overflow: hidden;
        height: fit-content;
        margin-bottom: 6rem;
    }
   .hero-image-container>svg{
        transform: none;
        width:100%;
    }
}
.pf-content-section{
    text-align: center;
}
/* hero section end */

/* featured projects start*/

.featured-projects-list {
    /* display: flex;
    flex-direction: column; */
    display: grid;
    grid-template-rows: auto auto auto auto;
    gap:2rem;
}
.featured-projects-list>a{
    text-decoration: none;
}
.featured-projects-list-row{
    display: flex;
    flex-direction: row;
    gap:2rem;
}
.featured-projects-list-row>a{
    width:100%;
    text-decoration: none;
}
.featured-projects-list-row>a>section>div:nth-child(2){
    margin:0 auto;
}
.featured-projects-list-row>a>section>div>img{
    max-width: 452px;
    width:100%;
}
@media (max-width: 598px) {
    .featured-projects-list-row{
      flex-direction: column;
    }
    .featured-projects-list-row>a>.imagecard{
        width:auto;
    }
}
/* featured projects end*/

/* project list start*/

.project-list{
    display: flex;
    flex-direction: column;
    align-items: start;
    gap:2rem;
}
.project-list>a{
    text-decoration: none;
}
/* project list end*/


/*about me start*/
.about-container{
    display: flex;
    flex-direction: column;
    gap:1rem;
}

@media (max-width: 598px) {
    .about-container{
        gap:4rem;
    }
    
}

.about-header{
    display:flex;
    gap:2rem;
    align-items: center;
}
.about-header>div>.text-container{
    display: flex;
    flex-direction: column;
    gap:1rem;
}
.about-header>div:nth-child(1){
    display: flex;
    flex-direction: column;
    gap:2rem;
    width:50%;
}
.about-header>div:nth-child(2){
    display: flex;
    flex-direction: column;
    gap:2rem;
    width:50%;
}
.about-header>div>svg{
    width:100%;
}
.list-container-experience{
    grid-column-start: 1;
    grid-column-end: 2;
    display: flex;
    flex-direction: column;
    gap:1.5rem;
    min-width: 0;
}
.list-container-honors{
    grid-column-start: 3;
    grid-column-end: 4;
    display: flex;
    flex-direction: column;
    gap:1.5rem;
    min-width: 0;
}
.list-rows{
    display: flex;
    flex-direction: column;
    gap:0.75rem;
}
.list-title{
    display: block;
    width:70%;
    white-space: nowrap;
    overflow: hidden;  
    text-overflow: ellipsis;
}
.list-subtitle{
    color:var(--color-gray-text);
    white-space: nowrap;
}
.list-row{
    height:2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-gray-outline);
    gap:1rem;
}
.about-summary{
    display: grid;
    grid-template-columns: 1fr 2rem 1fr;
}

@media (max-width: 598px) {
    .about-header{
        flex-direction: column-reverse;
        align-items: start;
        gap:0;
    }
    .about-header>div:nth-child(1){
        width: 100%;
    }
    .about-header>div:nth-child(2){
        width: 100%;
    }
    .about-summary{
        grid-template-rows: 1fr 4rem 1fr;
        grid-template-columns: 1fr;
    }
    .list-container-experience{
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 1;
    }
    .list-container-honors{
        grid-row-start: 3;
        grid-row-end: 4;
        grid-column-start: 1;
    }
  }
/*about me end*/


/* footer start */
footer{
    display: flex;
    flex-direction: row;
    padding:0.625rem 1.5rem;
    border-top:1px solid var(--color-gray-outline);
    justify-content: space-between;
    gap:0.5rem;
    flex-wrap: wrap;
}
footer>div>h6{
text-wrap: nowrap;
}
.footer-contact{
    display: flex;
    justify-content: center;
    gap:2rem;
}
.footer-contact>div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    gap:0.5rem;
}
.footer-contact>div>span{
    padding-top: 2.5px;
}

@media (max-width: 598px) {
    footer{
        flex-direction: column;
        gap:0.5rem;
    }
    .footer-contact{
        justify-content: start;
        gap:0.5rem;
        flex-wrap: wrap;
    }
    .footer-contact>div{
        justify-content: start;
    }
  }
/* footer end */
/*imagecard no text start*/
.imagecard-no-text{
    border-radius: 0.75rem;
    width:auto;
    display: block;
    overflow: hidden;
    border:1px solid var(--color-gray-outline);
}
.imagecard-no-text>img{
    color: transparent;
    object-fit: cover;
    object-position: left top;
    max-width: 100%;
    max-height: 100%;
    height: auto;
    display: block;
}
/*imagecard no text end*/

/*pf-hero section start*/
.pf-hero-section{
    display: flex;
    flex-direction: column;
    gap:1rem;
    text-align: center;
}
.pf-hero-section>h1{
    margin-bottom: 3.5rem;
}

@media (max-width: 598px) {
    .pf-hero-section{
        gap:1rem;
    }
}
/*pf-hero section end*/

/* summary-block start*/
.summary-block{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:1rem;
    margin-top: 2rem;
    margin-bottom: 7rem;
}
.summary-block>span{
    color:var(--color-gray-text);
    font-weight: var(--font-weight-subtitle);
    font-size:var(--font-size-subtitle);
    line-height:var(--font-line-height-subtitle);
}
.summary-block>p{
    width:70%;
    color:var(--color-black);
    font-weight: var(--font-weight-body-summary);
    font-size:var(--font-size-body-summary);
    line-height:var(--font-line-height-body-summary);
}
@media (max-width: 598px) {
    .summary-block>p{
        width:100%;
        color:var(--color-black);
        font-weight: var(--font-weight-body-1);
        font-size:var(--font-size-body-1);
        line-height:var(--font-line-height-body-1);
        
    }
    .summary-block{
        margin-bottom: 0;
        margin-top: 1rem;
    }
}
/* summary-block end*/

/* insight block start*/
.insight-block{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:0.5rem;
    margin-top: 12rem;
    margin-bottom: 3rem;
}

.insight-block>h3{
    margin-bottom: 0.5rem;
    width:70%;
}


.insight-block>p{
    width:70%;
    color:var(--color-gray-text);
    font-weight: var(--font-weight-body-hero);
    font-size:var(--font-size-body-hero);
    line-height:var(--font-line-height-body-summary);
}
@media (max-width: 598px) {
    .insight-block{
        gap:0rem;
        margin-top: 5rem;
        margin-bottom: 2rem;
    }
    .insight-block>h3{
        margin-bottom: 0.5rem;
        width:100%;
        font-weight: var(--font-weight-body-hero);
        font-size:var(--font-size-body-hero);
        line-height:var(--font-line-height-body-summary);
    }
    .insight-block>p{
        width:100%;
        color:var(--color-black);
        font-weight: var(--font-weight-body-1);
        font-size:var(--font-size-body-1);
        line-height:var(--font-line-height-body-1);
    }
}
/* insight blcok end*/

/*meta data start*/
.meta-data-block{
    background-color: var(--color-gray-bg);
    border-radius: 0.75rem;
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    min-width: 11rem;
}
.meta-data-block>h4{
    text-align: left;
}

.meta-data-rows{
    display: flex;
    flex-direction: column;
    gap:1rem;
    margin-bottom:5rem;
}

.meta-data-row-1{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap:1rem;
    width: 100%;
}
.meta-data-row-1>div{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap:1rem;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0; 
}
.meta-data-row-2{
    display: flex;
    flex-grow: 1;
}

/*meta datas end*/


/*link block start*/

.link-block{
    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: center;
    width:100%;
}
.link-block>h4{
    display: flex;
    gap:0.5rem;
    text-align: left;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width:100%;
}
.link-block>h4>a{
    color: var(--color-blue-link);
    white-space: nowrap;
    overflow: hidden;  
    text-overflow: ellipsis;
}
.link-block>h4>span{
    text-align: center;
}
@media (max-width: 1740px) {
    .link-block>h4>span{
    width:100%;
    }
}
/*link block end*/

/*video block start*/

.video-block>video{
    border-radius: 1.5rem;
    overflow: hidden;    
    border: 12px solid var(--color-black);
}
.video-block.denali>video{
    border-radius: 1.5rem;
    overflow: hidden;    
    border-radius: 0.75rem;
    border: 1px solid var(--color-gray-outline);
    width:100%;
}
/*video block end*/

.error-message-container{
    display: flex;
    height:100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:2rem;
    padding:0 1rem;
}
.error-message-container>h1{
    text-align: center;
}
.error-message-container>p{
    max-width: 30rem;
    text-align: center;
}
.error-message-container>p>a{
    color:var(--color-blue-link);
}
.two-result-images{
    display: flex;
    gap:2rem;
    flex-direction: column;
}