:root {
  /* font size */
  --font-size-h1:3.875rem;
  --font-size-h2:2.75rem;
  --font-size-h3:2rem;
  --font-size-h4:1.125rem;
  --font-size-h5:1rem;
  --font-size-h6:1rem;
  --font-size-body-1: 1rem;
  --font-size-body-2: 1rem;
  --font-size-body-hero: 1.25rem;
  --font-size-body-summary: 1.25rem;
  --font-size-button:1rem;
  --font-size-nav:0.875rem;
  --font-size-subtitle:0.875rem;
  /* font weight */
  --font-weight-h1:700;
  --font-weight-h2:700;
  --font-weight-h3:700;
  --font-weight-h4:700;
  --font-weight-h5:700;
  --font-weight-h6:500;
  --font-weight-body-1: 400;
  --font-weight-body-2: 400;
  --font-weight-body-hero: 500;
  --font-weight-body-summary: 600;
  --font-weight-button:500;
  --font-weight-nav:500;
  --font-weight-subtitle:400;

  /* font line height */
  --font-line-height-h1:auto;
  --font-line-height-h2:auto;
  --font-line-height-h3:auto;
  --font-line-height-h4:auto;
  --font-line-height-h5:auto;
  --font-line-height-h6:auto;
  --font-line-height-body-1: 1.5rem;
  --font-line-height-body-2: 1.25rem;
  --font-line-height-body-hero: 2rem;
  --font-line-height-body-summary: 2rem;
  --font-line-height-button:auto;
  --font-line-height-nav:auto;
  --font-line-height-subtitle:auto;
  
  /*color*/
  --color-white:#feffff;
  --color-black:#232931;
  --color-black-hover:#232931c9;
  --color-gray-text:#6F6F70;
  --color-gray-bg:#F4F4F6;
  --color-gray-bg-hover:#e1e1e3;
  --color-gray-bg-2:#F6F4F4;
  --color-gray-bg-3:#F4F6F4;
  --color-gray-outline:#E5E5E5;
  --color-blue-link:#0040DD;
  
}
@media (max-width: 598px) {
  :root{
    /* font size */
    --font-size-h1:2.875rem;
    --font-size-h2:2.25rem;
    --font-size-h3:1.875rem;
    --font-size-body-summary:1rem;
  }
}
/*divider*/
hr{
  width:100%;
  border: none; /* Removes the default border */
  height: 1px; /* Sets the height of the <hr> */
  margin-top:3rem;
  margin-bottom:3rem;
  background-color: var(--color-gray-outline);
}

body, button {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
ul{
  margin:0;
  padding:0;
  list-style-type: none;
}
/*container start*/
.hero-container-item{
  overflow:visible;
}
.container{
  width:75rem;
  padding-top:8rem;
  margin: 0 auto;
}
.container.about{
  height:calc(100vh - 8rem - 45px); 
}
.container-item{
  padding-left: 4rem;
  padding-right:4rem;
  margin-bottom: 11rem;
}
@media (max-width: 1200px) {
  .container{
    width:100vw;
    overflow-x: hidden;

  }
  .container-item{
    padding-left: 4rem;
    padding-right:4rem;
  }
  
}
@media (max-width: 598px) {
  .container-item{
    padding-left: 2rem;
    padding-right:2rem;
  }
  .container.about{
    height: auto;
  }
}
@media (max-width: 400px) {
  .container-item{
    padding-left: 1rem;
    padding-right:1rem;
    margin-bottom: 7rem;
  }
  .container, .about{
    min-height: auto;
  }
}

/*container end*/
/*buttons start*/
button{
  border-radius: 0.375rem;
  border:none;
  height: 2.25rem;
  padding:0.5rem 0.75rem;
  font-size: var(--font-size-button);
  line-height: var(--font-line-height-button);
  font-weight: var(--font-weight-button);
  width: fit-content;
  transition: background-color 0.3s;
}
.primary-button{
  background-color: var(--color-black);
  color:var(--color-white);
}
.secondary-button{
  background-color: var(--color-gray-bg);
  color:var(--color-black);
}
.tertiary-button{
  background-color: var(--color-white);
  color:var(--color-black);
}
.primary-button:hover{
  background-color:var(--color-black-hover);
  cursor: pointer;
}
.secondary-button:hover{
  background-color: var(--color-gray-bg-hover);
  cursor: pointer;
}
/*buttons ends*/

/*text start*/
h1{
  margin:0;
  padding: 0; 
  font-size:var(--font-size-h1);
  line-height: var(--font-line-hieght-h1);
  font-weight: var(--font-weight-h1);
  color:var(--color-black);
}

h2{
  margin:0;
  padding:0;
  font-size:var(--font-size-h2);
  line-height: var(--font-line-height-h2);
  font-weight: var(--font-weight-h2);
  color:var(--color-black);
}

h3{
  margin:0;
  padding:0;
  font-size:var(--font-size-h3);
  line-height: var(--font-line-height-h3);
  font-weight: var(--font-weight-h3);
  color:var(--color-black);
}

h4{
  margin:0;
  padding:0;
  font-size:var(--font-size-h4);
  line-height: var(--font-line-height-h4);
  font-weight: var(--font-weight-h4);
  color:var(--color-black);
}


h5{
  margin:0;
  padding:0;
  font-size:var(--font-size-h5);
  line-height: var(--font-line-height-h5);
  font-weight: var(--font-weight-h5);
  color:var(--color-black);
}

h6{
  margin:0;
  padding:0;
  font-size:var(--font-size-h6);
  line-height: var(--font-line-height-h6);
  font-weight: var(--font-weight-h6);
  color:var(--color-black);
}

p{
  margin:0;
  padding:0;
  font-size:var(--font-size-body-1);
  line-height: var(--font-line-height-body-1);
  font-weight: var(--font-weight-body-1);
  color:var(--color-black);
}

.body-2{
  margin:0;
  padding:0;
  font-size:var(--font-size-body-2);
  line-height: var(--font-line-height-body-2);
  font-weight: var(--font-weight-body-2);
  color:var(--color-gray-text);
}

a{
  text-decoration: none;
}
/*text end*/

/*image card satrt*/
.imagecard{
  background-color: var(--color-gray-bg);
  border: 2px solid var(--color-gray-bg);
  border-radius: 0.75rem;
  padding: 1.5rem 2rem 0rem 2rem;
  min-height: 26.25rem;
  width:auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap:2rem;
  overflow: hidden;
}
.imagecard-description>h4{
margin-bottom: 0.375rem;
}
.imagecard-description-icon{
  margin-bottom: 1.5rem;
}
.imagecard-description-icon>img{
  color: transparent;
  object-fit: cover;
  object-position: left top;
  max-width: 100%;
  max-height: 100%;
  height: 1.5rem;
  display: block;
}
.imagecard>div>img{
  color: transparent;
    object-fit: cover;
    object-position: left top;
    max-width: 100%;
    max-height: 100%;
    height: auto;
    display: block;
}
/*image card end*/

/* card with description start */
.card-and-description{
  display:flex;
  flex-direction: row;
  gap:2em;
}
.card-and-description>.image-container{
  width:16rem;
  border-radius: 0.375rem;
  background-color: var(--color-gray-bg);
  display: flex;
  align-items: center;
  overflow: hidden;
}

.card-and-description>.text-container{
  width:70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:0.375rem;
}
.card-and-description>.image-container>img{
  width:100%;
}
@media (max-width: 598px) {
  .card-and-description{
    flex-direction: column;
    gap:1.5rem;
  }
  .card-and-description>.image-container{
    width:100%;
  }
  .card-and-description>.text-container{
    width:100%;
  }
}
/* card with description start */